<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="is_show" slot-scope="text, record">
          <a-switch @change="handleEdit(record)" :checked="text"/>
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-notify-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { createNotify, putNotify, deleteNotify, notifyList } from '@/api/applet_shopmall_order'
import CreateNotifyForm from '@/views/a-applet/shop_manage/CreateNotifyForm'
export default {
  name: 'TableList',
  components: {
    STable,
    CreateNotifyForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '公告内容',
          ellipsis: true,
          dataIndex: 'content'
        },
        {
          title: '是否显示',
          ellipsis: true,
          dataIndex: 'is_show',
          scopedSlots: { customRender: 'is_show' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return notifyList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleCreate () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (this.mdl) {
            putNotify(values, this.mdl.id).then((res) => {
              this.visible = false
              if (res.code === 1000) {
                this.$refs.table.refresh()
              }
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            createNotify(values).then((res) => {
              this.visible = false
              if (res.code === 1000) {
                this.$refs.table.refresh()
              }
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        } else {
          this.confirmLoading = false
        }
        this.mdl = {}
      })
    },
    handleDelete (item) {
      console.log(item)
      deleteNotify(item.id).then((res) => {
        console.log(res, '删除成功')
        this.$refs.table.refresh()
      })
    },
    handleEdit (item) {
      console.log(item)
      // this.visible = true
      // this.confirmLoading = false
      this.mdl = item
      this.loading = true
      putNotify({ is_show: !item.is_show }, this.mdl.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      }).finally(() => {
        this.loading = false
        this.mdl = undefined
      })
    }
  }
}
</script>
